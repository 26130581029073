
<template>
	
	<div class="upperWrap support">
		<div class="subVisWrap" style="">
		<div class="innerWrap">
			<div class="form">
				<div class="inqForm">
					<h3 class="pb20 "><span class="b">매뉴얼 다운로드</span></h3>
						<div class="imgWrap03 mb20" style="">
							<img src="img/n_main/sp01.png" alt="">
						</div>
						<p class="pb10 ">
							최고관리자, 관리자를 위한 <br>
							aptok 이용 매뉴얼입니다.
						</p>
						
						<div class="mt10 ac">
							<a href="/files/APTOK_매뉴얼.zip" class="btn btnLineBasic"><i class="fas fa-file-word mr10" download></i>관리자용 매뉴얼 <span class="mOff">다운로드</span></a>
						</div>
				</div>
				<div class="inqForm type02">
					<h3 class="">전화 문의</h3>
					<p class="pb20"> <span class="pointC more">02-858-3001</span> </p>
					<p> 상담시간 : 오전10시 ~ 오후5시까지 <br>(점심시간: 12시~1시)</p>
				</div>
				<div class="inqForm type02">
					<h3><i class="fas fa-map-signs"></i></h3>
					<h3 class="">찾아오시는 길</h3>
					<p class="map pt10">
						서울특별시 구로구 디지털로31길 38-21, 701호 (구로동, 이앤씨벤처드림타워3차)
					</p>
				</div>
			</div>
		</div>

		</div>
	</div>
	
</template>

<script>
export default {
  name: 'Support',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
